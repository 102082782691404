.formContainer {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.formButtonsContainer {
  display: flex;
  justify-content: space-between;
}

.formButton {
  margin: 0 2px;
  margin-top: 30px;
  flex-grow: 1;
}